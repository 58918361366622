import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-chatbot-button',
  templateUrl: './ai-chatbot-button.component.html',
  styleUrls: ['./ai-chatbot-button.component.scss'],
})
export class AiChatbotButtonComponent implements OnInit {
  aiChatbotState: boolean = false;

  constructor() {}

  ngOnInit(): void { }

  changeAiChatbotState() {
    this.aiChatbotState = !this.aiChatbotState;
  }
}
