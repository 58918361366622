// floating-button.service.ts
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AiChatbotService {

  constructor(
    private http: HttpClient,
  ) { }

  aiChatbotResponse(data, recaptchaToken) {
    const headers = {
      'Authorization': `Bearer ${environment.aiChatBotApiKey}`,
      'Accept': 'application/json',
      'g-recaptcha-response': recaptchaToken,
    };

    return this.http.post(`${environment.aiChatBotBaseUrl}/v1/chat/stream`, data, { headers, observe: 'response' });
  }

  aiChatbotRating(data, rating) {
    const headers = {
      'Authorization': `Bearer ${environment.aiChatBotApiKey}`,
      'Accept': 'application/json'
    };

    return this.http.post(`${environment.aiChatBotBaseUrl}/v1/chat/review/`+rating, data, { headers });
  }
}
