import { Component, ElementRef } from "@angular/core";

import { NavigationEnd, Router, ActivatedRoute, NavigationStart, RouterEvent } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { FloatingButtonService } from './service/floating-button.service';
import { HelperService } from "./service/helper.service";

@Component({
  selector: "app-root",
  template: `
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss'],
})

// @Component({
//   selector: "app-root",
//   template: `
//     <app-header></app-header>
//     <router-outlet></router-outlet>
//   `,
//   styleUrls: ['./app.component.scss'],
// })
export class AppComponent {
  title = 'CompareInsV2';
  timed :boolean = false;
  timedOut = false;
  lastPing?: Date = null;
  countdown: any;
  showUrl:boolean = true;
  language: string;
  currentRoute = "";
  showButton = false;
  showReferralBar = false;

  constructor(
    public floatingButtonService: FloatingButtonService, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private translate: TranslateService,
    private elRef: ElementRef,
    private helperService: HelperService,
    ) {}

  async initI18n() {
    this.language = 'en';
    // NOTE: check from pathname instead of ActivatedRoute as it is slow.
    let selectedLanguage = location.pathname.split('/')[1];

    this.language = this.helperService.mapLanguage(selectedLanguage) ?? 'en';

    document.documentElement.setAttribute('lang', this.language);
    this.translate.use(this.language);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // NOTE: Skip EN as no need language prefix.
        if (this.language == 'en') return;

        // NOTE: Skip prepending prefix if already prepended.
        if (event.url.includes(selectedLanguage)) return;

        const path = event.url !== '/' ? event.url : '';
        this.router.navigateByUrl(selectedLanguage + path);
      }
    })
  }

  ngOnInit() {
    this.initI18n();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.language = event.lang;
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
