import { NgModule } from "@angular/core";
import { Routes, RouterModule, Route } from "@angular/router";
import { AiChatbotPageComponent } from "./ai-chatbot-page/ai-chatbot-page.component";
import { i18nLanguagesSimplied } from "./staticdata/constants";
import { FloatingButtonComponent } from "./floating-button/floating-button.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [],
    children: [
      { path: "", component: AiChatbotPageComponent },
      { path: "floating-button-page", component: FloatingButtonComponent },
    ],
  }
];

const i18nRoutes: Routes = [
  ...i18nLanguagesSimplied
    .filter(lang => lang !== 'en')
    .map((lang) => {
      return {
        path: lang,
        canActivate: [],
        children: [
          ...routes[0].children
        ]
      } as Route;
    }),
  ...routes,
]

@NgModule({
  imports: [RouterModule.forRoot(i18nRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
