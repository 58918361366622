import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AiChatbotService } from '../service/ai-chatbot.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ai-chatbot-page',
  templateUrl: './ai-chatbot-page.component.html',
  styleUrls: ['./ai-chatbot-page.component.scss']
})
export class AiChatbotPageComponent implements OnInit {

  @ViewChild("mobileRating") mobileRating: ElementRef<any>;
  drawer: boolean = false;
  rating: boolean = false;
  ratingMobileChecker: boolean = false;
  ratingResponse: boolean = false;
  ratingResponseError: boolean = false;
  sessionId: string = "";
  constructor(
    private aiChatbotService: AiChatbotService,
    private router: Router,
  ) {}
  
  ngOnInit(): void {}

  setDrawer() {
    this.drawer = !this.drawer;
  }

  getDrawer(): boolean {
    return this.drawer;
  }

  setRating() {
    this.rating = !this.rating;
  }

  getRating(): boolean {
    return this.rating;
  }

  async callRatingApi(rating) {
    this.ratingResponseError = false;
    try {
        const data = {
            [rating]: true, 
            session_id: this.sessionId
        }

        this.aiChatbotService.aiChatbotRating(data, rating).subscribe((response) => {
          if (response['object'][rating] == true) {
            this.ratingResponse = true;
          } else {
            this.ratingResponseError = true;
          }
        });
    } catch (e) {
      this.ratingResponseError = true;
    }
  }

  animation(element, property, value1, value2) {
    element.nativeElement.animate(
      [{ [property]: value1 }, { [property]: value2 }],
      {
        fill: "forwards",
        easing: "ease-in-out",
        duration: 1000,
        iterations: 1
      },);

  }

  openMobileRatingMethod() {
    this.ratingMobileChecker = true;
    this.animation(this.mobileRating, 'backgroundColor', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.5)');
  }

  closeMobileRatingMethod() {
    this.ratingMobileChecker = false;
    this.ratingResponse = false;
  }

  setSessionId(event) {
    this.sessionId = event;
  }
}
