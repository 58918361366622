<div class="container">
    <div class="chatbox-app">
        <div class="header">
            <div class="chat-header">
                <button class="back-button" (click)="openMobileRatingMethod()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                        <g id="Show_more_Icon" data-name="Show more Icon" transform="translate(28 28) rotate(180)">
                            <rect id="Rectangle_5971" data-name="Rectangle 5971" width="28" height="28" fill="none" />
                            <g id="Group_11397" data-name="Group 11397" transform="translate(21 17) rotate(180)">
                                <g id="Group_227" data-name="Group 227">
                                    <path _ngcontent-phq-c216="" id="Path_14" data-name="Path 14" d="M14,0,7,7,0,0" fill="none"
                                        stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
                                        stroke-width="2"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
                <div class="logo">
                    <img src="assets/images/ai-chatbot/chat-icon.webp" alt="chat-icon" />
                </div>
                <div class="title">
                    <span class="beta-status">
                        BETA
                    </span>
                    <span>Chat with MyEG AI Chatbot</span>
                </div>
                <button class="menu-button" (click)="setDrawer()">
                    <svg width="9" height="32" viewBox="0 0 9 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="4.5" cy="4.5" r="4.5" fill="white" />
                        <circle cx="4.5" cy="15.75" r="4.5" fill="white" />
                        <circle cx="4.5" cy="27" r="4.5" fill="white" />
                    </svg>
                </button>
            </div>
            <div class="chat-desc">
                <p [innerHTML]="'chatbot.page.description' | translate"></p>
            </div>
            <!-- Get rating template for desktop -->
            <div *ngIf="getRating()" class="rating-desktop">
                <ng-container *ngTemplateOutlet="rating"></ng-container>
            </div>
        </div>
        <app-ai-chatbot-chatbox class="chatbox" (showRating)="setRating()" (currentSession)="setSessionId($event)">
            {{ 'chatbot.page.welcome.message' | translate }}
        </app-ai-chatbot-chatbox>
    </div>
</div>

<app-drawer *ngIf="getDrawer()" (closeDrawer)="setDrawer()">
    <div class="drawer-text">
        <p [innerHTML]="'chatbot.page.description' | translate"></p>
    </div>
</app-drawer>

<!-- Get rating template for mobile -->
<div [style.display]="ratingMobileChecker == true ? 'flex' : 'none'" class="mobile-rating-container">
    <button class="rating-mobile" (click)="closeMobileRatingMethod()" #mobileRating></button>
    <div class="rating-template-wrapper">
        <ng-container *ngTemplateOutlet="rating"></ng-container>
    </div>
</div>

<!-- Rating template -->
<ng-template #rating>
    <div class="rating">
        <button *ngIf="ratingMobileChecker" class="close-button" (click)="closeMobileRatingMethod()">X</button>
        <p>{{ "chatbot.page.rating.desc" | translate }}</p>
        <div class="button-wrapper">
            <button (click)="callRatingApi('like')">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M23 20.4844V9.70312C23 9.10769 22.5173 8.625 21.9219 8.625H18.3281C17.7327 8.625 17.25 9.10769 17.25 9.70312V20.4844C17.25 21.0798 17.7327 21.5625 18.3281 21.5625H21.9219C22.5173 21.5625 23 21.0798 23 20.4844ZM21.2031 11.5C21.2031 12.0954 20.7204 12.5781 20.125 12.5781C19.5296 12.5781 19.0469 12.0954 19.0469 11.5C19.0469 10.9046 19.5296 10.4219 20.125 10.4219C20.7204 10.4219 21.2031 10.9046 21.2031 11.5ZM8.98439 0C9.89105 0 10.3089 1.76512 10.5086 2.59626C10.7425 3.56954 10.9843 4.57597 11.6493 5.24225C13.1079 6.70329 13.8731 8.56499 15.6526 10.3234C15.7033 10.3735 15.7435 10.4331 15.7709 10.4988C15.7983 10.5645 15.8125 10.635 15.8125 10.7062V20.3091C15.8125 20.603 15.5769 20.8427 15.2832 20.8481C14.572 20.8611 13.6348 21.256 12.918 21.5749C11.4915 22.2095 9.71622 22.9992 7.54712 23H7.41936C5.49774 23 3.22533 22.9814 2.30843 21.6642C1.93144 21.1225 1.83917 20.4497 2.03225 19.6592C1.29948 18.8932 0.906372 17.4642 1.29634 16.301C0.50823 15.2484 0.436401 13.7794 0.878208 12.7311L0.873266 12.7261C0.33901 12.1894 -0.00374222 11.3219 3.05176e-05 10.5161C0.00703812 9.15265 1.17505 7.90625 2.67526 7.90625H7.24489C6.91664 6.63316 5.75002 5.56438 5.75002 3.65898C5.75002 0.359375 7.90627 0 8.98439 0Z"
                        fill="white" />
                </svg>
                <p>{{ "chatbot.page.rating.good" | translate }}</p>
            </button>
            <button (click)="callRatingApi('dislike')">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 2.51562L0 13.2969C0 13.8923 0.482685 14.375 1.07812 14.375H4.67187C5.26731 14.375 5.74999 13.8923 5.74999 13.2969V2.51562C5.74999 1.92019 5.26731 1.4375 4.67187 1.4375H1.07812C0.482685 1.4375 0 1.92019 0 2.51562ZM1.79687 11.5C1.79687 10.9046 2.27956 10.4219 2.875 10.4219C3.47043 10.4219 3.95312 10.9046 3.95312 11.5C3.95312 12.0954 3.47043 12.5781 2.875 12.5781C2.27956 12.5781 1.79687 12.0954 1.79687 11.5ZM14.0156 23C13.1089 23 12.6911 21.2349 12.4914 20.4037C12.2575 19.4305 12.0157 18.424 11.3507 17.7578C9.8921 16.2967 9.1269 14.435 7.34737 12.6766C7.29673 12.6265 7.25653 12.5669 7.22909 12.5012C7.20165 12.4355 7.18753 12.365 7.18754 12.2938V2.69087C7.18754 2.39703 7.42306 2.15728 7.71685 2.15189C8.42801 2.13887 9.36521 1.74405 10.082 1.42515C11.5085 0.790535 13.2838 0.000763672 15.4529 0L15.5806 0C17.5023 0 19.7747 0.0185527 20.6916 1.33584C21.0686 1.87746 21.1608 2.55026 20.9678 3.3408C21.7005 4.10685 22.0936 5.53581 21.7037 6.69902C22.4918 7.75163 22.5636 9.22057 22.1218 10.2689L22.1267 10.2739C22.661 10.8106 23.0037 11.6781 23 12.4839C22.993 13.8473 21.8249 15.0938 20.3247 15.0938H15.7551C16.0834 16.3668 17.25 17.4356 17.25 19.341C17.25 22.6406 15.0937 23 14.0156 23Z"
                        fill="white" />
                </svg>
                <p>{{ "chatbot.page.rating.bad" | translate }}</p>
            </button>
        </div>
        <p *ngIf="ratingResponse" class="thank-you">{{ "chatbot.page.rating.thanks" | translate }}</p>
        <p *ngIf="ratingResponseError" class="thank-you">{{ "chatbot.page.rating.try.again" | translate }}</p>
    </div>
</ng-template>