<div class="ai-chatbot-button-container">
    <div class="chatbox-wrapper"  *ngIf="aiChatbotState">
        <div class="chat-header">
            <button class="back-button" (click)="changeAiChatbotState()">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                    <g id="Show_more_Icon" data-name="Show more Icon" transform="translate(28 28) rotate(180)">
                        <rect id="Rectangle_5971" data-name="Rectangle 5971" width="28" height="28" fill="none" />
                        <g id="Group_11397" data-name="Group 11397" transform="translate(21 17) rotate(180)">
                            <g id="Group_227" data-name="Group 227">
                                <path _ngcontent-phq-c216="" id="Path_14" data-name="Path 14" d="M14,0,7,7,0,0" fill="none"
                                    stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
                                    stroke-width="2"></path>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
            <div class="logo">
                <img src="assets/images/ai-chatbot/chat-icon.webp" alt="chat-icon" />
            </div>
            <div class="title">
                <span>CompareGenie (Beta)</span>
                <span class="bot-status">
                    <div class="green-status"></div>
                    Online
                </span>
            </div>
        </div>
        <app-ai-chatbot-chatbox class="chatbox" (closeButton)="changeAiChatbotState()">
            <div [innerHTML]="'chatbot.button.welcome.message' | translate"></div>
        </app-ai-chatbot-chatbox>
    </div>
    <button class="ai-chatbot-button" (click)="changeAiChatbotState()">
        <img *ngIf="!aiChatbotState" width="106px" height="112px" src="assets/images/ai-chatbot/AiChatbotButton.webp">
        <img *ngIf="aiChatbotState" width="60px" height="60px" src="assets/images/ai-chatbot/AiChatbotCloseButton.webp">
    </button>
</div>



  