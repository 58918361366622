import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { BasicAuthInterceptor } from './_helpers/basic-auth.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule, Routes } from '@angular/router';
import { AiChatbotButtonComponent } from './ai-chatbot-button/ai-chatbot-button.component';
import { AiChatbotChatboxComponent } from './ai-chatbot-chatbox/ai-chatbot-chatbox.component';
import { WebpackTranslateLoader } from './webpack-translate-loader';
import { AiChatbotPageComponent } from './ai-chatbot-page/ai-chatbot-page.component';
import { FloatingButtonComponent } from './floating-button/floating-button.component';
import { DrawerComponent } from './drawer/drawer.component';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TextFieldModule } from '@angular/cdk/text-field';
// import { HeaderComponent } from './header/header.component';

const appRoutes: Routes = [
  // Define other routes as needed
];

@NgModule({
  declarations: [
    AppComponent,
    // HeaderComponent,
    AiChatbotButtonComponent,
    AiChatbotChatboxComponent,
    AiChatbotPageComponent,
    FloatingButtonComponent,
    DrawerComponent,
  ],
  imports: [
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ModalModule.forRoot(),
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      }
    }),
    RouterModule.forRoot(appRoutes),
    TextFieldModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    DatePipe,
  ],
  exports: [
    // other exported modules here
    TranslateModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
