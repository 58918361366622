export const TAKAFUL_MYEG_AGENT_LINK: string = 'https://www.motortakaful.com/motor-takaful-cyberagent?code=4e3168d3-d08e-414a-afae-1b1db3a905e5';
export const EMAIL_REGEX = /^[a-zA-Z0-9._?~!$%^.&*_=+‘}{'?`\/|#\-`]+@([a-zA-Z0-9_.-])+[\w-]{2,4}$/;

export const languageCodeMap = {
    'en': ['en'], // default
    'ms': ['ms', 'my', 'ms-MY', 'bm'],
    'zh': ['zh-CN', 'zh', 'cn'],
}

export const i18nLanguages = [
    { lang: 'en', name: 'English' },
    { lang: 'ms', name: 'Bahasa' },
    { lang: 'zh', name: 'Chinese (中文)' },
  ];

/** `i18nLanguages` with only `lang` */
export const i18nLanguagesSimplied = i18nLanguages.map(lang => lang.lang);
