import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {

  @Output('closeDrawer') closeDrawer: EventEmitter<void> = new EventEmitter();
  @ViewChild("drawerContainer") drawerContainer: ElementRef<any>;
  @ViewChild("drawer") drawer: ElementRef<any>;

  position: { y: number } = { y: 0 };
  lastPosition: { y: number };
  clicked: boolean = false;
  halfscreen = screen.height / 2;
  drawerHeight: number;

  constructor() { }

  ngOnInit(): void {
    //Set position to half the screen
    this.position.y = this.halfscreen;
  }

  ngAfterViewInit() {
    this.openDrawerMethod();
  }

  animation(element, property, value1, value2) {
    element.nativeElement.animate(
      [{ [property]: value1 }, { [property]: value2 }],
      {
        fill: "forwards",
        easing: "ease-in-out",
        duration: 1000,
        iterations: 1
      },);

  }

  openDrawerMethod() {
    this.animation(this.drawerContainer, 'backgroundColor', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.5)');
    this.animation(this.drawer, 'top', '100%', '50%');
  }

  closeDrawerMethod() {
    //Prevents multiple clicks
    if (this.clicked) {
      return;
    }

    this.animation(this.drawerContainer, 'backgroundColor', 'rgba(0, 0, 0, 0.5)', 'rgba(0, 0, 0, 0)');
    this.animation(this.drawer, 'top', '50%', '100%');
    this.clicked = true;

    setTimeout(() => {
      this.closeDrawer.emit();
    }, 1000);
  }

  startDrag($event): void {
    $event.preventDefault();
    const mouseY = $event.clientY;
    const positionY = this.position.y;

    const duringDrag = (e) => {
      const dy = e.clientY - mouseY;
      this.position.y = positionY + dy;
      this.lastPosition = { ...this.position };

      this.drawerHeight = this.position.y > 0 ? this.position.y : 0;
      this.drawer.nativeElement.setAttribute('style', 'top:' + this.drawerHeight + 'px !important');
    };

    const finishDrag = (e) => {
      this.drawer.nativeElement.removeEventListener('pointermove', duringDrag);
      this.drawer.nativeElement.removeEventListener('pointerup', finishDrag);

      //When drawer is above halfscreen height and pointer up, will expand to top
      const topPixel = this.drawerHeight + 'px';
      this.drawer.nativeElement.setAttribute('style', 'top:none');
      if (this.position.y < this.halfscreen) {
        this.animation(this.drawer, 'top', topPixel, '0px');
        //Sets drawer positon to 0
        this.position.y = 0;
      } else {
        this.animation(this.drawerContainer, 'backgroundColor', 'rgba(0, 0, 0, 0.5)', 'rgba(0, 0, 0, 0)');
        const fullscreen = (this.halfscreen * 2) + 'px';
        this.animation(this.drawer, 'top', topPixel, fullscreen);

        setTimeout(() => {
          this.closeDrawer.emit();
        }, 1000);
      }
    };

    this.drawer.nativeElement.addEventListener('pointermove', duringDrag);
    this.drawer.nativeElement.addEventListener('pointerup', finishDrag);
  }
}
