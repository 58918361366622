import { Component, OnInit, HostListener, ViewChild, ElementRef, NgZone, Output, EventEmitter, Input } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AiChatbotService } from '../service/ai-chatbot.service';
interface AiChatMessage {
  /** enum: `user` or `bot` */
  who: string;
  /** HTMLDomString */
  content: string; 
}

@Component({
  selector: 'app-ai-chatbot-chatbox',
  templateUrl: './ai-chatbot-chatbox.component.html',
  styleUrls: ['./ai-chatbot-chatbox.component.scss']
})
export class AiChatbotChatboxComponent implements OnInit {
  @ViewChild('vChatBody') chatBody: ElementRef;
  @ViewChild('vTxtInput') txtInput: ElementRef;
  @ViewChild('vSend') send: ElementRef;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Output('showRating') showRating: EventEmitter<void> = new EventEmitter();
  @Output('currentSession') currentSession: EventEmitter<string> = new EventEmitter<string>();

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
  
  DEFAULT_ANSWER =
    "Sorry, the Compare Bot is busy at the moment. Please ask your question again later.";

  sessionId = "";

  iSpeed = 10; // time delay of print out
  iTextPos = 0; // initialise text position

  dt = Date.now();

  messages: Array<AiChatMessage> = [];

  loading = false;
  noOfResponse: number = 0;

  constructor(
    private _ngZone: NgZone,
    private aiChatbotService: AiChatbotService,
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    window?.grecaptcha.ready(function () { });
  }

  renderUserMessage() {
    const userInput = this.txtInput.nativeElement.value;
    if (userInput === "") {
      alert("Please insert any input");
      return;
    }
    this.txtInput.nativeElement.value = "";

    this.messages.push(
      {
        who: 'user',
        content: userInput,
      }
    );
    setTimeout(() => {
      this.setScrollPosition();
    }, 100);
    this.getChatbotResponse(userInput);
  };

  replaceURLWithHTMLLinks(text) {
    let exp1 =
      /\[([-A-Z0-9+&@#\/%?=~_|!:,.; ]*)\]\(([-A-Z0-9+&@#\/%?=~_|!:,.;]*)\)/i;
    let exp2 =
      / (\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
    let exp3 = /\\n/g;
    let exp4 = /\\"/g;
    let exp5 = /\\/g;
    let exp6 = /\"/g;
    let res1 = text.replace(exp1, "<a href='$2' target='_blank'>$1</a>");
    let res2 = res1.replace(exp2, " <a href='$1' target='_blank'>$1</a>");
    return res2.replace(exp3, "<br />").replace(exp4, '').replace(exp5, '').replace(exp6, '');
  }

  updateSessionId(newSessionId) {
    this.sessionId = newSessionId;
  };

  async getChatbotResponse(userInput) {
    let recaptchaToken = await window?.grecaptcha.execute(
      "6LcMB0EkAAAAAMxCuZi_aOLPNJtw58djUHaM82gG",
      { action: "submit" }
    );

    try {
      this.loading = true;
      const data = {
        query: userInput,
        session_id: this.sessionId
      }

      const response = await this.aiChatbotService.aiChatbotResponse(data, recaptchaToken).toPromise();

      // Fetch the session_id from the response headers
      const newSessionId = response.headers.get("session_id");
      this.updateSessionId(newSessionId); // Update the session ID

      this.messages.push(
        {
          who: 'bot',
          content: this.replaceURLWithHTMLLinks(response.body),
        }
      );
      this.loading = false;
      this.setScrollPosition();
    } catch (error) {
      this.loading = false;
      this.messages.push(
        {
          who: 'bot',
          content: this.DEFAULT_ANSWER,
        }
      );
    }
    setTimeout(() => {
      this.setScrollPosition();
    }, 100);

    this.noOfResponse++;
    //Emits once chatbot response twice
    if (this.noOfResponse == 2) {
      this.showRating.emit();
    }
    this.currentSession.emit(this.sessionId);
  };

  setScrollPosition() {
    let chatMessages = [...this.chatBody.nativeElement.children[1].children];
    let latestMessage = chatMessages[chatMessages.length - 1];
    this.chatBody.nativeElement.scrollTop = latestMessage.offsetTop - 55;
  };
}
