// floating-button.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FloatingButtonService {
  showButton = true;

  hideButton() {
    this.showButton = false;
  }

  showButtonOnAllPages() {
    this.showButton = true;
  }
}
