import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent implements OnInit {

  openExternalUrl(url: string) {
    window.open(url, '_blank');
  }

  // Show the button 
  isButtonVisible = false;

  @HostListener('window:scroll', [])
  onScroll(): void {
    this.isButtonVisible = (window.scrollY || document.documentElement.scrollTop || document.body.scrollTop) > 300; // Threshold 
    // console.log(this.isButtonVisible);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  constructor() { }

  ngOnInit(): void {
  }

}
