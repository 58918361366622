import { Injectable } from "@angular/core";
import { languageCodeMap } from "../staticdata/constants";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  /**
   * Maps i18n localization from API of different keys into the app's language keys.
   *
   * e.g. Maps `ms-MY` key to `ms`
   * @param from Key value pair from API
   * @param to Key value pair to render on UI according to language
   * @returns `to`
   */
  mapAndPushLanguage(from: object, to: object = {}) {
    for (let [locale, content] of Object.entries(from)) {
      for (let [lang, trans] of Object.entries(languageCodeMap)) {
        if (trans.includes(locale)) {
          to[lang] = content;
        }
      }
    }

    return to;
  }

  mapLanguage(locale){
    for (let [lang, trans] of Object.entries(languageCodeMap)) {
      if (trans.includes(locale)) {
        return lang;
      }
    }
  }
}
