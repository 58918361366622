<div class="ai-chatbox">
    <div class="chatbox">
        <div class="container-chat">
            <div class="chat-body" #vChatBody>
                <div class="time-date-chatbot">Today, {{ dt | date:'shortTime' }}</div>
                <!-- Streamed messages will be appended here -->
                <div class="chat-messages">
                    <!-- Initial Welcome Message -->
                    <p class="chat-message chatbot-message">
                        <ng-content></ng-content>
                    </p>
                    <ng-container *ngFor="let message of messages">
                        <p [class]="'chat-message ' + (message.who === 'user' ? 'user-message' : 'chatbot-message')" [innerHTML]="message.content"></p>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="loading" class="loading">
                <img class="loading-icon" src="assets/images/ai-chatbot/loading-dots.gif" width="498" height="148">
                <span>AI Chatbot MyEG is typing...</span>
            </div>
            <div class="chat-input">
                <div class="input-sec">
                    <textarea placeholder="Send a message" autofocus (keyup.enter)="renderUserMessage()" #vTxtInput
                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMaxRows="2"></textarea>
                </div>
                <div class="send-wrapper">
                    <button (click)="renderUserMessage()" class="send-button" #vSend>
                        <img src="assets/images/ai-chatbot/send.svg" alt="send" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>



  